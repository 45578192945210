<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="8">
                <form-select placeholder="Pilih kategori/kosongkan untuk parent" ref="categorySelect" @value-changed="categoryChange" :rules="{required: false}" url="v1/item_category_header_select2" v-model="id_parent" label="Kategori Induk"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama Kategori"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: false}" v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'itemcategories'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect
  },
  data(){
    return {
      id: this.$route.params.id||null,
      id_parent:null,
      name:'',
      code:'',
      note:'',
      name_parent:'',
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'itemcategories/store',
      dispatchShow: 'itemcategories/show',
      dispatchUpdate: 'itemcategories/update',
    }),
    categoryChange(json){
      if(json.label) this.name_parent = json.label;
      else this.name_parent = '';
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.note = data.note
      this.id_parent = data.id_parent
      this.name_parent = data.name_parent
      if (data.id_parent) this.$refs.categorySelect.initOption([{"value":data.id_parent,"label":data.name_parent}])
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,name,note,id_parent,name_parent} = this
      let params = {id,code,name,note,id_parent,name_parent}
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'itemcategories'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>